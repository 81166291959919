/* You can add global styles to this file, and also import other style files */

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:root  {
  --lpx-logo: url('/assets/images/logo/logo-light.png');
  --lpx-logo-icon: url('/assets/images/logo/logo-light-thumbnail.png');
}
.donut {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;

  &.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media print {
  body * {
    visibility: hidden;
  }
}
